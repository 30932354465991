// import React from "react";
import React, { useState } from "react";
// import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
// import { css } from "styled-components/macro"; //eslint-disable-line

// import headerBg from "../../images/header.png";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks
} from "../headers/light.js";
import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { Link } from "react-router-dom";
// import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";

// import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }

  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("/img/header.jpg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-secondary-700 focus:shadow-outline focus:outline-none transition duration-300`;
// const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

// const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;
const Notification2 = tw.span`inline-block my-4 pl-3 py-1 text-yellow-500 border-l-4 border-yellow-500 font-medium text-lg text-yellow-400`;

// const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-gray-300 transition duration-300 hocus:text-yellow-400 focus:outline-none`}
  ${tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;
// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default () => {
  const buttonRoundedCss = true && tw`rounded-full`;

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Beranda</NavLink>
      <NavLink href="/alur-pendaftaran">Alur Pendaftaran</NavLink>
    </NavLinks>,

    <NavLinks key={2}>
      {/* <NavLink href="/#" tw="lg:ml-12!">
        Login
      </NavLink> */}
      {/* <NavLink href="#">Hafidzh</NavLink>
      <NavLink href="#">Pricing</NavLink> */}

      {/* <PrimaryLink css={buttonRoundedCss} href="/beasiswa">
        Info Beasiswa
      </PrimaryLink> */}
      {/* <NavLink href="/beasiswa">Info Beasiswa</NavLink> */}
      <PrimaryLink css={buttonRoundedCss} href="/beasiswa">
        Info Beasiswa
      </PrimaryLink>
      {/* <PrimaryLink css={buttonRoundedCss} href="https://e-pmb.unismuh.ac.id/">
        Daftar e-PMB
      </PrimaryLink> */}
    </NavLinks>
  ];

  const StyledModal = styled(ReactModalAdapter)`
    &.mainHeroModal__overlay {
      ${tw`fixed inset-0 z-50`}
    }
    &.mainHeroModal__content {
      ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
    }
    .content {
      ${tw`w-full lg:p-16`}
    }
  `;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Notification>
              Selamat kepada para penerima beasiwa untuk Hafidzh, berperestasi akademik dan Bibit
              Unggul Persyrikatan{" "}
            </Notification>
            <Heading>
              <SlantedBackground>Beasiswa</SlantedBackground>
              <br />
              {/* <span><small>Universitas Muhammadiyah Makassar</small></span> */}
              <span>Universitas Muhammadiyah Makassar</span>
              {/* <br /> */}
            </Heading>
            {/* <PrimaryLink css={buttonRoundedCss} href="https://e-pmb.unismuh.ac.id/">
            Daftar Sekarang
            </PrimaryLink> */}
            {/* <Link to='https://e-pmb.unismuh.ac.id/'> */}
            {/* <PrimaryAction
              onClick={e => {
                e.preventDefault();
                window.location.href = "https://e-pmb.unismuh.ac.id/";
              }}
            >
              Daftar e-PMB
            </PrimaryAction> */}
            {/* <Actions>
              <PrimaryButton as="a" href="https://e-pmb.unismuh.ac.id/">
                Daftar e-PMB
              </PrimaryButton>
              <WatchVideoButton onClick={toggleModal}>
                <span className="playIconContainer">
                  <PlayIcon className="playIcon" />
                </span>
                <span className="playText">Video Panduan e-PMB</span>
              </WatchVideoButton>
            </Actions> */}

            <Actions>
              {/* <NavLink href="https://e-pmb.unismuh.ac.id/">Daftar e-PMB disini</NavLink> */}
              {/* <PrimaryButton as="a" href="https://beasiswa.unismuh.ac.id/download/PENGUMUMAN%20PENERIMA%20BEASISWA%20BUMM.pdf">
                Pengumuman Penerima Beasiswa
              </PrimaryButton> */}
              
              &#160;&#160;

              <PrimaryButton as="a" href="https://e-pmb.unismuh.ac.id/">
                Daftar e-PMB
              </PrimaryButton>
              <WatchVideoButton onClick={toggleModal}>
                <span className="playIconContainer">
                  <PlayIcon className="playIcon" />
                </span>
                <span className="playText">Video Panduan e-PMB</span>
              </WatchVideoButton>
            </Actions>
            <Notification2>
              {/* Silahkan lakukan pendaftaran di e-PMB Jika belum terdaftar  */}
              <p>
                <b>Jika belum terdaftar di e-PMB </b> Silahkan lakukan pendaftaran terlebih dahulu di e-PMB, sebelum mendaftar Program Beasiswa
                
              </p>

              {/* <span className="playText"><a href="https://e-pmb.unismuh.ac.id/"> Daftar e-PMB disini</a></span> */}
            </Notification2>

            {/* <PrimaryAction as="a" href='https://e-pmb.unismuh.ac.id/'>
              Daftar Sekarang
            </PrimaryAction> */}

            {/* </Link> */}
          </LeftColumn>
          {/* <RightColumn>
            <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            />
          </RightColumn> */}
        </TwoColumn>
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <ResponsiveVideoEmbed
              url="https://www.youtube.com/embed/l6QJPXGzdJQ"
              tw="w-full"
            />
          </div>
        </StyledModal>
      </HeroContainer>
    </Container>
  );
};
