import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import HafidzhImage from "../../images/hafidzh.png";
import RankingImage from "../../images/ranking.png";
import BibitImage from "../../images/rank2.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`;
// const CardText = tw.div`mt-4`;
// const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: HafidzhImage,
      title: "Hafidzh Qur’an",
      description: (
        <p>
          <b>Beasiswa Hafidzh Qur’an (BUMM-H)</b> yang dipersiapkan bagi
          siswa-siswa Penghafal Al-Qur’an
        </p>
      ),
      link: "/bumm-h"
    },
    {
      imageSrc: RankingImage,
      title: "Prestasi Akademik",
      description: (
        <p>
          <b>Beasiswa Prestasi Akademik (BUMM-PA)</b> yang dipersiapkan bagi
          Siswa-siswi yang memiliki peringkat akadmik 1-5
        </p>
      ),
      link: "/bumm-pa"
    },
    {
      imageSrc: BibitImage,
      title: "Bibit Unggul Persyarikatan",
      description: (
        <p>
          <b>Beasiswa Bibit Unggul Persyarikatan (BUMM-BUP)</b> yang
          dipersiapkan bagi siswa-siswi yang aktif mengikuti kegiatan organisasi
          Ikatan Pelajar Muhammadiyah (IPM)
        </p>
      ),
      link: "/bumm-bup"
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Jenis <span tw="text-primary-500">Beasiswa</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>

              <Link href={card.link || "#"}>Info Lanjut</Link>
             
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
