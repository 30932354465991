import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import BoxBeasiswa from "components/beasiswa/BoxBeasiswa.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/Beasiswa.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import Footer from "components/footers/MiniCenteredFooter.js";
import Footer from "components/footers/unismuh.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";


import macHeroScreenshotImageSrc from "images/mac-unismuh.png";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";





export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`
  return (
    <AnimationRevealPage>
      <Hero />
      {/* <FeatureStats /> */}
      {/* <MainFeature /> */}
      {/* <Blog /> */}
      <BoxBeasiswa />
      {/* <MainFeature2 /> */}
      
      {/* <Portfolio /> */}
      {/* <Testimonial
        subheading="Testimonials"
        heading={
          <>
            Our Clients <span tw="text-primary-500">Love Us.</span>
          </>
        }
        description="Here are what some of our amazing customers are saying about our marketing professionals. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        testimonials={[
          {
            imageSrc:
              "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
            customerName: "Charlotte Hale",
            customerTitle: "CEO, Tesla Inc."
          },
          {
            imageSrc:
              "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, Nestle"
          }
        ]}
        textOnLeft={true}
      /> */}
      {/* <FeatureWithSteps
          subheading={<Subheading>Alur Pendaftaran</Subheading>}
          heading={
            <>
              5 Langkah <HighlightedText>Daftar Beasiswa</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={macHeroScreenshotImageSrc}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
      <FAQ
        imageSrc={customerSupportIllustrationSrc}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Ada <span tw="text-primary-500">Pertanyaan ?</span>
          </>
        }
      /> */}
      
      {/* <ContactUsForm /> */}
      <Footer />
    </AnimationRevealPage>
  );
}

