import React from "react";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
// import Features from "components/features/ThreeColSimple.js";
// import MainFeature from "components/features/TwoColWithButton.js";
import Alur from "components/features/TwoColSingleFeature3.js";
// import TabGrid from "components/cards/TabCardGrid.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/unismuh.js";

// import chefIconImageSrc from "images/chef-icon.svg";
// import celebrationIconImageSrc from "images/celebration-icon.svg";
// import shopIconImageSrc from "images/shop-icon.svg";
import headerImageSrc from "images/design-illustration.svg";
import registrasiImageSrc from "images/registrasi1.jpg";
import isidataImageSrc from "images/isi-data3.png";
import validasiImageSrc from "images/validasi.jpg";
import seleksiImageSrc from "images/seleksi.jpg";
import pengumumanImageSrc from "images/student-registration.jpg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  // const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  // const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            Alur Pendaftaran <HighlightedText>Beasiswa UNISMUH</HighlightedText>
          </>
        }
        description=""
        imageSrc={headerImageSrc}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Daftar e-PMB"
        watchVideoButtonText="Video Panduan e-PMB"
        watchVideoYoutubeUrl="https://www.youtube.com/embed/l6QJPXGzdJQ"
      />

      <Alur
        subheading={<Subheading>1 Februari 2021</Subheading>}
        heading={
          <>
            <HighlightedText>1.</HighlightedText> Registrasi
          </>
        }
        description={
          <>
            <p>
              Calon Pendaftar melakukan registrasi di e-pmb.unismuh.ac.id
              dan memilih program beasiswa yang tersedia.
            </p>
          
            
          </>
        }
        imageInsideDiv={false}
        imageSrc={registrasiImageSrc}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Alur
        subheading={<Subheading>-</Subheading>}
        heading={
          <>
            <HighlightedText>2.</HighlightedText> Isi Data
          </>
        }
        description={
          <>
            <p>
              Mengisi Data secara Lengkap sesuai isian yang ada pada Formulir
              Pendaftaran
            </p>
            <br />
            <p>
              Mengupload dokumen persyaratan sesuai beasiswa yang akan didaftari
            </p>
            <br />
            <p>Satu orang hanya dapat mengisi formulir satu kali</p>
          </>
        }
        imageInsideDiv={false}
        imageSrc={isidataImageSrc}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />
      <Alur
        subheading={<Subheading>-</Subheading>}
        heading={
          <>
            <HighlightedText>3.</HighlightedText> Validasi
          </>
        }
        description={
          <>
            <p>Validasi dilakukan oleh panitia</p>
            <br />
            <p>
              Pendaftar yang <b>Memenuhi Syarat</b>
              akan diumumkan melalui website, media sosial dan secara personal
              oleh panitia, serta diberikesempatan untuk melengkapi persyaratan
              yang kurang.
            </p>
            <br />
            <p>
              Pendaftar yang <b>tidak memenuhi syarat </b> akan diumumkan
              melalui website, media sosial dan secara personal oleh panitia,
              serta diberikesempatan untuk melengkapi persyaratan yang kurang.{" "}
            </p>
          </>
        }
        imageInsideDiv={false}
        imageSrc={validasiImageSrc}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Alur
        subheading={<Subheading>-</Subheading>}
        heading={
          <>
            <HighlightedText>4.</HighlightedText> Seleksi
          </>
        }
        description={
          <>
            <p>
              Data dari pendaftar akan diseleksi oleh TIM yang telah ditunjuk
              oleh Rektor Universitas Muhammadiyah Makassar
            </p>
            <br />
            <p>
              Melakukan Tes Hapalan Al-Quran (Khusus bagi pendaftar Beasiswa
              Hafidzh Qur'an)
            </p>
            <br />
            <p>Mengikuti Test Wawancara</p>
            <br />
            <p>Hal lain yang dianggap perlu</p>
          </>
        }
        imageInsideDiv={false}
        imageSrc={seleksiImageSrc}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />
      <Alur
        subheading={<Subheading>15 Mei 2020</Subheading>}
        heading={
          <>
            <HighlightedText>5.</HighlightedText> Pengumuman
          </>
        }
        description={
          <>
            <p>
              Pendaftar yang dinyatakan lulus akan dihubungi langsung oleh
              panitia dan diumumkan melalui website serta media sosial.
            </p>
            <br />
            
          </>
        }
        imageInsideDiv={false}
        imageSrc={pengumumanImageSrc}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />

      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />
      <DownloadApp
        text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>Treact App.</HighlightedTextInverse></>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
