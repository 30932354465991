import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/HafidzHero.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import MainFeature from "components/features/TwoColWithButton.js";
import Syarat from "components/syarat/HafidzhSyarat.js";
import Kategori from "components/kategori/HafidzKategori.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/HafidzhFaq.js";
import Footer from "components/footers/unismuh.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg";
import serverSecureIllustrationImageSrc from "images/syarat-dokumen.png";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";

export default () => {
  const description = (
    <>
      <ul  class="list-decimal sm:list-disc md:list-decimal lg:list-disc xl:list-none">
        
        <li><span className="text">Menunjukkan bukti Munakasyah Hafisdzh Qur’an</span></li>
        <br/>
        <li>Telah mengikuti seleksi hafal mutqin pada panitia Seleksi UNISMUH Makassar</li>
        <br/>
        <li>Dapat mempertahankan hafalannya selama mengikuti studi</li>
        <br/>
        <li>Menanda tangani surat perjanjian mempertahankan prestasi (IP 3,0) selama study</li>
        <br/>
        <li>Menanda tangani surat pernyataan siap mengikuti proses pembinaan mahasiswa Unismuh Makassar.</li>
        <br/>
        <li>Lulus seleksi bagi Calon Maba Prodi Pendidikan Dokter</li>
        <br/>
        <li>Bagi Prodi Non Kedokteran sekaligus dinyatakan bebas seleksi</li>
        <br/>
        <li>Mengikuti Test Wawancara</li>
      </ul>
    </>
  );
  return (
    <AnimationRevealPage>
      <Hero />
      {/* <Features /> */}
      <Kategori />
      {/* <MainFeature 
        subheading="Reliable"
        heading="Highly Redundant Servers With Backup"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
      /> */}
      <Syarat
        subheading="Hafidz"
        heading="Persyaratan UMUM"
        description={description}
        imageSrc={serverSecureIllustrationImageSrc}
        // buttonRounded={true}
        textOnLeft={false}
      />
      {/* <Testimonial /> */}
      <FAQ />
      <Footer />
    </AnimationRevealPage>
  );
};
