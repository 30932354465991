import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(
  Container
)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "Ada Pertanyaaan",
  description = "Kami telah merangkum pertanyaan yang sering diajukan seputar Beasiswa Bibit Unggul Persyarikatan",
  faqs = [
    {
      question:
        "Apakah Program Beasiswa ini berlaku untuk semua mahasiswa UNISMUH",
      answer: "Tidak, Beasiswa ini hanya untuk mahasiswa baru tahun 2021/2022"
    },
    {
      question:
        "Saya telah terima di UNISMUH angkatan 2021/2022 dan telah melakukan pembayaran, apakah pembayaran saya akan dikembalikan jika saya lulus dalam program ini?",
      answer:
        "Selamat atas kelulusan anda, silahkan mendaftarkan diri pada salah satu program beasiswa, jika anda lulus dari salah satu program beasiswa ini maka kami akan mengembalikan kelebihan dana yang telah dibayarkan, jika beasiswa 100% maka kami akan mengembalikan penuh dana tersebut"
    },
    // {
    //   question:
    //     "Dalam raport saya telah menyantumkan peringkat/ranking saya dikelas, apakah masih perlu melampirkan Surat Keterangan Peringkat dari Kepala Sekolah atau Wakasek Bidang Kurikulum ",
    //   answer: "...."
    // },
    {
      question: "Adakah kontak person yang saya bisa hubungi?",
      answer: (
        <div>
          Silahkan hubungi kami : <br />
          SUHENA - <b>0852 9997 9558</b> <br />
          Maria Ulviani - <b>0852 4750 5002 </b>
          <br />
          Muhammad Fakhrul - <b>0852 5653 5421 </b>
          <br />
          Nurhidayat - <b>0853 9935 6819 </b>
          <br />
          Muhammad Amin - <b>0852 9995 9593 </b>
          <br />
        </div>
      )
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: {
              opacity: 1,
              height: "auto",
              marginTop: "16px",
              display: "block"
            },
            collapsed: {
              opacity: 0,
              height: 0,
              marginTop: "0px",
              display: "none"
            }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
